import { Fragment, h } from "preact";
import { User, ZohoPerson } from "../../types/servertypes";

export function breakLines(string: string) {
    return string.split("\n").map((a,i) => i == 0 ? a :  h(Fragment, {}, [h('br',{}),a]));
}


// @ts-ignore
export const groupByKey =         (list: T[], key: keyof T) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})


// @ts-ignore
export const resourceGroupByKey = (list: ({data: {properties:T}})[], key: keyof T) => list.reduce((hash, obj) => ({...hash, [obj.data.properties[key]]:( hash[obj.data.properties[key]] || [] ).concat(obj)}), {})



export function getUserName(user:User) {
    return  user.properties.fullName || Object.values(user.data.externalAccounts || {} ).find((a) => a.fullName)?.fullName || 
            user.properties.givenName || Object.values(user.data.externalAccounts || {} ).find((a) => a.givenName)?.givenName || 
            user.properties.name;
}

export function getUserGivenName(user:User) {
    return   user.properties.givenName || Object.values(user.data.externalAccounts || {} ).find((a) => a.givenName)?.givenName || 
             user.properties.fullName || Object.values(user.data.externalAccounts || {} ).find((a) => a.fullName)?.fullName || 
             user.properties.name;
}




export function htmlEscape(str: string) {
    return str.replace(/[&<>'"]/g, tag => ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;'
    }[tag] as string));
}


export function dateOfThread(thread: any) {
    return new Date(thread.commentedTime ?? thread.createdTime);
}


export function extractEmailAddresses(email: string) {
    return email.split(',').map(m => m.match(/<(.*)>$/)?.[1].trim() || m).filter(Boolean);
}
export function extractEmailAddress(email: string) {
    return extractEmailAddresses(email).join(",");
}

export function getZohoPersonName(author: ZohoPerson) {
    return author?.name || ([author?.firstName, author?.lastName].filter(Boolean).join(" ")) || author?.email;
}


// this pains me to do (https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/)
const NameSplitRegex = /[\._ @]/;
export function extractFirstWord(str: string) {
    let fn = str.split(NameSplitRegex).shift() || "";
    return fn.replace(/^./, fn[0]?.toUpperCase());

}



export function getLinkFromEvent(e: MouseEvent): HTMLAnchorElement | undefined {
    let t = e.target as HTMLElement;
    do {
        //console.log("ctar", t);
        if (t.localName === "button" && !t.hasAttribute('popovertarget')) {
            e.preventDefault();
        };
        if (t.localName === "button" || t.localName === "input") 
            return;

        if (t.localName === 'a' && t.getAttribute('href')) {

            if (t.hasAttribute('data-native') || t.hasAttribute('native')) return;
            let href = t.getAttribute('href')!;
            let target = t.getAttribute('target');
            if (!href || !href.match(/^\//g) || (target && !target.match(/^_?self$/i)))
                return;
            e.preventDefault();
            return t as HTMLAnchorElement;
        }

    } while ((t = t.parentNode as HTMLElement));
}

